import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import PurchaseDetail from "./component/purchase-detail/PurchaseDetail";
import Login from "./component/login/Login";
import Verify from "./component/verify/Verify";
import { ThemeOptions, ThemeProvider, createTheme } from "@mui/material";
import Payment from "./Payment";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/payment",
    element: <Payment />,
  },
  {
    path: "/confirm-purchase",
    element: <PurchaseDetail />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
]);

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#009c4c",
    },
    secondary: {
      main: "#ee3c39",
    },
  },
};

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#009c4c",
    },
    secondary: {
      main: "#ee3c39",
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
