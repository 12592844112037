import Paper from "@mui/material/Paper";
import styles from "./Login.module.css";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IPaymentState } from "../../redux/reducer";
import { IRootState } from "../../redux/store";
import { PaymentActions } from "../../redux/action";
import { Backdrop, Chip, CircularProgress, Divider, Modal, Tooltip } from "@mui/material";
import Otp from "../otp/Otp";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";

export interface ISessionData {
  token: string;
  userId: string;
  guid: string;
  email: string;
  tokenType: string;
  mfa: string;
}

export interface ITranferInitData {
  name: string;
  nonce: string;
  mfaType: string;
  mfaStatus: boolean;
  balance: number;
}

const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const paymentState: IPaymentState = useSelector((state: IRootState) => state.payment);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isMfa, setIsMfa] = useState(false);
  const [mfaType, setMfaType] = useState("email");
  const [otp, setOtp] = useState("");
  const [errorOtp, setErrorOtp] = useState("");
  const [signUsingSkId, setSignUsingSkId] = useState(false);
  const [isSkIdVerified, setIsSkIdVerified] = useState(false);
  const [skId, setSkId] = useState("");
  const [openError, setOpenError] = useState(false);

  const onErrorModalClose = () => {
    window.location.replace(paymentState.purchaseDetail?.cancelUrl!);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const getUserDetailAndInitiatePayment = (sessionObj: ISessionData) => {
    if (!paymentState.purchaseDetail) return;

    const ssHeader = JSON.stringify({
      version: "1.0",
      clientKey: "END_USER_MOBILE_APP",
      moduleId: "1",
      sessionId: sessionObj.guid,
      accessToken: sessionObj.token,
    });

    const data = {
      senderId: sessionObj.userId,
      paymentSessionId: paymentState.sessionId,
      correlationId: "SETTLEKING_PAYMENT_FORM",
      amount: Math.round(parseFloat(paymentState.purchaseDetail.totalPrice) * 10 * 10),
      description: paymentState.purchaseDetail.description,
    };

    const header = {
      accept: "application/json",
      "ss-header": ssHeader,
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionObj.token}`,
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .post(`${process.env.REACT_APP_WALLET_END_PONIT}/wallet/merchantPaymentNonApi`, data, {
        headers: header,
        withCredentials: false,
      })
      .then((res) => {
        if (res.status === 200) {
          const response = res?.data;

          if (response) {
            const responseData = response.response;

            if (res?.data?.statusCode + "" === "200") {
              const txInitData: ITranferInitData = {
                name: responseData["name"],
                nonce: responseData["nonce"],
                mfaType: responseData["mfaType"],
                mfaStatus: responseData["mfaStatus"],
                balance: responseData["balance"],
              };
              dispatch(PaymentActions.SetTxnDetails(txInitData));
              dispatch(PaymentActions.SetLoadingStatus(false));
              navigate("/verify");
            } else {
              dispatch(PaymentActions.SetLoadingStatus(false));
              setError(res.data["message"]);
            }
          }
        } else {
          dispatch(PaymentActions.SetLoadingStatus(false));
          setError("We're unable to complete this transaction at the moment. Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
        // window.location.replace(paymentState.purchaseDetail?.cancelUrl!);
        setOpenError(true);
      });
  };

  function tryLogging(): void {
    dispatch(PaymentActions.SetLoadingStatus(true));
    setError("");
    axios
      .post(`${process.env.REACT_APP_CORE_END_PONIT}/auth/login`, {
        email,
        password,
      })
      .then((res) => {
        console.log(res);

        if (res.status === 200) {
          const responseData = res.data.response;
          if (responseData["mfa"] && responseData["mfaType"]) {
            setIsMfa(responseData["mfa"]);
            setMfaType(responseData["mfaType"]);
            dispatch(PaymentActions.SetLoadingStatus(false));
          } else {
            const sessionObj = {
              token: responseData["accessToken"] as string,
              userId: responseData["session"] as string,
              guid: responseData["session_guid"] as string,
              email: responseData["email"] as string,
              tokenType: responseData["tokenType"] as string,
              mfa: mfaType,
            };

            dispatch(PaymentActions.SetSessionDetails(sessionObj));
            getUserDetailAndInitiatePayment(sessionObj);
          }
        }
      })
      .catch((res) => {
        setError(res?.response?.data["message"]);
        dispatch(PaymentActions.SetLoadingStatus(false));
      });
  }

  function tryLoggingWithMfa(): void {
    dispatch(PaymentActions.SetLoadingStatus(true));
    setError("");
    axios
      .post(`${process.env.REACT_APP_CORE_END_PONIT}/auth/validateSign`, {
        userEmail: email,
        mfaType: mfaType,
        code: otp,
      })
      .then((res) => {
        if (res.status === 200) {
          const responseData = res.data.response;
          const sessionObj = {
            token: responseData["accessToken"] as string,
            userId: responseData["session"] as string,
            guid: responseData["session_guid"] as string,
            email: responseData["email"] as string,
            tokenType: responseData["tokenType"] as string,
            mfa: mfaType,
          };

          dispatch(PaymentActions.SetSessionDetails(sessionObj));
          getUserDetailAndInitiatePayment(sessionObj);
        }
      })
      .catch((res) => {
        if (res.response.status === 400) {
          setErrorOtp(res.response.data["message"]);
          setError(res.response.data["message"]);
        }
        dispatch(PaymentActions.SetLoadingStatus(false));
      });
  }

  function verifySKId(): void {
    dispatch(PaymentActions.SetLoadingStatus(true));
    setError("");
    axios
      .post(`${process.env.REACT_APP_CORE_END_PONIT}/auth/loginWithSkId`, {
        value: skId,
      })
      .then((res) => {
        if (res.status === 200) {
          const responseData = res.data.response;
          setMfaType(responseData["mfaType"]);
          setEmail(responseData["email"]);
          setIsSkIdVerified(true);
          dispatch(PaymentActions.SetLoadingStatus(false));
        }
      })
      .catch((res) => {
        if (res.response.status === 400) {
          setError(res.response.data["message"]);
        }
        dispatch(PaymentActions.SetLoadingStatus(false));
      });
  }

  return (
    <>
      {paymentState.loading && (
        <Backdrop sx={{ color: "green", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={paymentState.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <div>
        <div className={styles.container}>
          <Paper variant="outlined">
            <div>
              <div className={styles.head}>
                <img src="/sk-logo.svg" alt="settleking_logo" className={styles.logoImage} />
              </div>

              {signUsingSkId ? (
                <div className={styles.body}>
                  {!isMfa ? (
                    <>
                      <TextField
                        label="SettleKing ID"
                        variant="outlined"
                        style={{ width: "80%" }}
                        value={skId}
                        onChange={(event) => {
                          setError("");
                          setSkId(event.target.value);
                        }}
                      />
                      {isSkIdVerified && (
                        <span style={{ marginTop: "20px" }}>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: 14,
                              padding: 20,
                            }}
                          >
                            {mfaType === "email" ? "Please enter otp sent to your " : "Please enter the otp from your "}
                            <span style={{ textTransform: "capitalize" }}>{mfaType}</span>
                          </div>
                          <Otp
                            separator={<span />}
                            length={6}
                            value={otp}
                            onChange={(value) => {
                              setErrorOtp("");
                              setOtp(value);
                            }}
                          />
                        </span>
                      )}
                      {error.trim() !== "" && <div className={styles.error}>{error}</div>}
                    </>
                  ) : (
                    <>
                      <div style={{ paddingBottom: 40 }}>
                        {mfaType === "email" ? "Please enter otp sent to your " : "Please enter the otp from your "}
                        <span style={{ textTransform: "capitalize" }}>{mfaType}</span>
                      </div>
                      <Otp
                        separator={<span />}
                        length={6}
                        value={otp}
                        onChange={(value) => {
                          setErrorOtp("");
                          setOtp(value);
                        }}
                      />
                      {errorOtp.trim() !== "" && <div className={styles.error}>{errorOtp}</div>}
                      {error.trim() !== "" && <div className={styles.error}>{error}</div>}
                    </>
                  )}
                </div>
              ) : (
                <div className={styles.body}>
                  {!isMfa ? (
                    <>
                      <TextField
                        label="Email"
                        variant="outlined"
                        style={{ width: "80%" }}
                        value={email}
                        onChange={(event) => {
                          setError("");
                          setEmail(event.target.value);
                        }}
                      />
                      <div style={{ paddingTop: 24, width: "80%" }}>
                        <TextField
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          style={{ width: "100%" }}
                          value={password}
                          onChange={(event) => {
                            setError("");
                            setPassword(event.target.value);
                          }}
                        />
                      </div>
                      {error.trim() !== "" && <div className={styles.error}>{error}</div>}
                    </>
                  ) : (
                    <>
                      <div style={{ paddingBottom: 40 }}>
                        {mfaType === "email" ? "Please enter otp sent to your " : "Please enter the otp from your "}
                        <span style={{ textTransform: "capitalize" }}>{mfaType}</span>
                      </div>
                      <Otp
                        separator={<span />}
                        length={6}
                        value={otp}
                        onChange={(value) => {
                          setErrorOtp("");
                          setOtp(value);
                        }}
                      />
                      {errorOtp.trim() !== "" && <div className={styles.error}>{errorOtp}</div>}
                      {error.trim() !== "" && <div className={styles.error}>{error}</div>}
                    </>
                  )}
                </div>
              )}

              <div className={styles.action}>
                {!isMfa ? (
                  <>
                    {!signUsingSkId ? (
                      <Button
                        variant="contained"
                        sx={{ width: "80%" }}
                        disabled={email.trim() !== "" && password.trim() !== "" ? false : true}
                        onClick={tryLogging}
                      >
                        Log in
                      </Button>
                    ) : !isSkIdVerified ? (
                      <Button variant="contained" sx={{ width: "80%" }} disabled={skId.trim() !== "" ? false : true} onClick={verifySKId}>
                        Verify SettleKing ID
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ width: "80%" }}
                        disabled={(email.trim() !== "" && password.trim() !== "") || isSkIdVerified ? false : true}
                        onClick={tryLoggingWithMfa}
                      >
                        Log in
                      </Button>
                    )}
                    {!signUsingSkId && (
                      <>
                        <Divider style={{ marginTop: "20px" }}>
                          <Chip label="OR" size="medium" />
                        </Divider>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 13,
                            color: "#666",
                          }}
                        >
                          <Tooltip title="You can find your SettleKing ID in the profile section when you log in.">
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          If you have registered on SettleKing using social login please use Login with SettleKing Id
                        </div>
                        <Button variant="outlined" sx={{ width: "80%", marginTop: "20px" }} onClick={() => setSignUsingSkId(true)}>
                          Login with SettleKing Id
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <Button variant="contained" sx={{ width: "80%" }} disabled={otp.trim() !== "" ? false : true} onClick={tryLoggingWithMfa}>
                    Verify Otp
                  </Button>
                )}

                <Button
                  variant="text"
                  sx={{ fontSize: 11, fontWeight: 600 }}
                  style={{ marginTop: 24 }}
                  color="secondary"
                  onClick={() => {
                    window.location.replace(paymentState.purchaseDetail?.cancelUrl!);
                  }}
                >
                  Cancel and return to <br></br>
                  {paymentState.purchaseDetail?.merchantName}
                </Button>
              </div>
            </div>
          </Paper>
          <div className={styles.footer}>
            <div>
              <a href="https://settleking.com/auth/terms-of-service">Terms</a>
              <a href="https://settleking.com/auth/privacy-policy">Privacy Policy</a>
            </div>
            <div className={styles.footerRight}>
              <a href="https://settleking.com/">
                <img src="/sk-logo.svg" alt="settleking_logo" style={{ maxWidth: 70 }} />
              </a>
              <span>© 2024</span>
            </div>
          </div>
        </div>
      </div>
      <Modal open={openError} onClose={onErrorModalClose} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className={styles.errorModal}>
          <h2>Error Occurred</h2>
          <ErrorIcon fontSize="large" color="error" />
          <p style={{ textAlign: "center" }}>
            An error occurred while processing your request.<br></br> Please try again later.
          </p>
          <Button
            variant="contained"
            sx={{ fontSize: 11, fontWeight: 600 }}
            style={{ marginTop: 24 }}
            color="error"
            onClick={onErrorModalClose}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Login;
