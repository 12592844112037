import { createAction } from "@reduxjs/toolkit";
import { IPurchaseDetail } from "../component/purchase-detail/PurchaseDetail";
import { ISessionData, ITranferInitData } from "../component/login/Login";

export enum PaymentActionTypes {
  SetLoadingStatus = "payment/SetLoadingStatus",
  SetPurchaseDetails = "payment/SetPurchaseDetails",
  SetSessionId = "payment/SetSessionId",
  SetSessionDetails = "payment/SetSessionDetails",
  SetTxnDetails = "payment/SetTxnDetails",
}

export const PaymentActions = {
  SetLoadingStatus: createAction<boolean>(PaymentActionTypes.SetLoadingStatus),
  SetPurchaseDetails: createAction<IPurchaseDetail>(
    PaymentActionTypes.SetPurchaseDetails
  ),
  SetSessionId: createAction<string>(PaymentActionTypes.SetSessionId),
  SetSessionDetails: createAction<ISessionData>(
    PaymentActionTypes.SetSessionDetails
  ),
  SetTxnDetails: createAction<ITranferInitData>(
    PaymentActionTypes.SetTxnDetails
  ),
};
