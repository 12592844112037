import { configureStore } from "@reduxjs/toolkit";
import { IPaymentState, PaymentReducer } from "./reducer";

export const store = configureStore({
  reducer: {
    payment: PaymentReducer,
  },
});

export interface IRootState {
  payment: IPaymentState;
}
