import Paper from "@mui/material/Paper";
import styles from "./PurchaseDetail.module.css";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { IPaymentState } from "../../redux/reducer";
import { IRootState } from "../../redux/store";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getCurrencySymbol } from "../../util/getCurrencySymbol";

export interface IPurchaseDetail {
  totalPrice: string;
  merchant: string;
  successUrl: string;
  cancelUrl: string;
  currency: string;
  description: string;
  merchantName: string;
}

const PurchaseDetail = () => {
  const navigate = useNavigate();

  const paymentState: IPaymentState | undefined = useSelector(
    (state: IRootState) => state.payment
  );

  return (
    <>
      {paymentState.loading ? (
        <Backdrop
          sx={{ color: "green", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={paymentState.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        paymentState?.purchaseDetail && (
          <div className={styles.container}>
            <Paper variant="outlined">
              <div>
                <div className={styles.head}>
                  <div className={styles.titleText}>
                    {paymentState.purchaseDetail.merchantName}
                  </div>
                  <div className={styles.poweredBy}>
                    <span>Powered by </span>
                    <img
                      src="/sk-logo.svg"
                      alt="settleking_logo"
                      className={styles.logoImage}
                    />
                  </div>
                </div>

                <div className={styles.body}>
                  <Divider>
                    <Chip
                      label="Total Amount"
                      size="medium"
                      sx={{ fontWeight: 600 }}
                    />
                  </Divider>
                  <div className={styles.priceContainer}>
                    <span className={styles.priceBlock}>
                      {getCurrencySymbol(
                        paymentState.purchaseDetail.currency,
                        parseFloat(paymentState.purchaseDetail.totalPrice)
                      )}
                    </span>
                  </div>
                </div>

                <div className={styles.action}>
                  <Button
                    variant="contained"
                    sx={{ width: "80%" }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Continue
                  </Button>

                  <Button
                    variant="text"
                    sx={{ fontSize: 11, fontWeight: 600 }}
                    style={{ marginTop: 24 }}
                    color="secondary"
                    onClick={() => {
                      window.location.replace(
                        paymentState.purchaseDetail?.cancelUrl!
                      );
                    }}
                  >
                    Cancel and return to <br></br>
                    {paymentState.purchaseDetail.merchantName}
                  </Button>
                </div>
              </div>
            </Paper>
            <div className={styles.footer}>
              <div>
                <a href="https://settleking.com/auth/terms-of-service">Terms</a>
                <a href="https://settleking.com/auth/privacy-policy">
                  Privacy Policy
                </a>
              </div>
              <div className={styles.footerRight}>
                <a href="https://settleking.com/">
                  <img
                    src="/sk-logo.svg"
                    alt="settleking_logo"
                    style={{ maxWidth: 70 }}
                  />
                </a>
                <span>© 2024</span>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default PurchaseDetail;
