import { createReducer } from "@reduxjs/toolkit";
import { PaymentActions } from "./action";
import { produce } from "immer";
import { IPurchaseDetail } from "../component/purchase-detail/PurchaseDetail";
import { ISessionData, ITranferInitData } from "../component/login/Login";

export interface IPaymentState {
  purchaseDetail?: IPurchaseDetail;
  sessionDetail?: ISessionData;
  txInit?: ITranferInitData;
  sessionId?: string;
  loading: boolean;
}

const initialState: IPaymentState = {
  loading: true,
};

export const PaymentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PaymentActions.SetPurchaseDetails, (state, action) => {
      return produce(state, (draft) => {
        draft.purchaseDetail = action.payload;
      });
    })
    .addCase(PaymentActions.SetSessionId, (state, action) => {
      return produce(state, (draft) => {
        draft.sessionId = action.payload;
      });
    })
    .addCase(PaymentActions.SetLoadingStatus, (state, action) => {
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });
    })
    .addCase(PaymentActions.SetSessionDetails, (state, action) => {
      return produce(state, (draft) => {
        draft.sessionDetail = action.payload;
      });
    })
    .addCase(PaymentActions.SetTxnDetails, (state, action) => {
      return produce(state, (draft) => {
        draft.txInit = action.payload;
      });
    })
    .addDefaultCase((state) => state);
});
