import { useNavigate, useSearchParams } from "react-router-dom";

import { useEffect } from "react";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { PaymentActions } from "./redux/action";
import { IPaymentState } from "./redux/reducer";
import { IRootState } from "./redux/store";

function Payment() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const paymentState: IPaymentState = useSelector((state: IRootState) => state.payment);

  useEffect(() => {
    if (searchParams.get("session") !== null) {
      const session = searchParams.get("session")!;
      dispatch(PaymentActions.SetSessionId(session));
      axios
        .post(`${process.env.REACT_APP_CORE_END_PONIT}/auth/payment-button/get-tx-details`, {
          value: session,
        })
        .then((res) => {
          const data = res.data.response;
          if (data) {
            let totalPrice = "0";
            let merchant = "";
            let successUrl = "";
            let cancelUrl = "";
            let currency = "";
            let description = "";
            let merchantName = "";
            for (const [key, value] of Object.entries(data)) {
              if (key === "total") {
                totalPrice = value as string;
              } else if (key === "merchant_id") {
                merchant = data[key];
              } else if (key === "success_url") {
                successUrl = data[key];
              } else if (key === "cancel_url") {
                cancelUrl = data[key];
              } else if (key === "currency") {
                currency = data[key];
              } else if (key === "description") {
                description = data[key];
              } else if (key === "merchant_name") {
                merchantName = data[key];
              }
            }

            dispatch(
              PaymentActions.SetPurchaseDetails({
                totalPrice,
                merchant,
                successUrl,
                cancelUrl,
                currency,
                description,
                merchantName,
              })
            );

            dispatch(PaymentActions.SetLoadingStatus(false));
            navigate("/confirm-purchase");
          }
        });
    }
  }, [dispatch, navigate, searchParams]);

  return (
    <div>
      <Backdrop sx={{ color: "green", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={paymentState.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Payment;
